<template>
  <div>
    <div class="footer">
      <app-download v-if="showDownloadIcon" />
      <hr style="border: solid 1px #efefef" />
      <div class="footer-contents">
        <a class="footer-link" @click="contactClicked">Contact us</a>
        <!-- 
        <a
          class="footer-link"
          href="https://www.youtube.com/channel/UCUTC5YzaNyQwgIRrhqeCsKw"
          >YouTube</a
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import AppDownload from "./../components/AppDownload.vue";
export default {
  props: {
    needDownloadIcon: Boolean,
  },
  components: {
    AppDownload,
  },
  data: function () {
    return {
      imgUrl: "https://yhmango.com/wp-content/uploads/2022/03/share.png",
      title: "디망고 페이지",
      content: "소소한 게임 모음",
      isShowing: false,
      showDownloadIcon: false,
    };
  },
  mounted() {
    console.log(this.showDownloadIcon + "?");
    this.showDownloadIcon = this.needDownloadIcon == true;
  },
  methods: {
    contactClicked() {
      window.location.href = window.location.origin + "/contact";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Proza+Libre");
.footer {
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}
.footer-contents {
  padding-top: 0.3rem;
  padding-bottom: 0.6rem;
}
.footer-link {
  font-size: 0.8rem;
  font-family: "Proza Libre", sans-serif;
  margin-left: 1rem;
  margin-right: 1rem;
  text-decoration: unset;
  font-weight: 300;
  color: #868686;
}
.footer-link:hover {
  color: #097957;
}
</style>
