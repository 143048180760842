
class SfxItem {
    constructor(address, poolSize) {
        this.poolSize = poolSize;
        if (this.poolSize <= 0) {
            return;
        }

        this.index = 0;
        let audio = new Audio(address);
        audio.preload = true;

        this.audios = [];

        for (let i = 0; i < poolSize; i++) {
            this.audios.push(audio.cloneNode());
        }
    }

    play(volume) {
        if (this.poolSize <= 0) {
            return;
        }

        if (volume != null) {
            this.audios[this.index].volume = volume;
        }
        this.audios[this.index].play();
        this.index++;
        if (this.index >= this.audios.length) {
            this.index = 0;
        }
    }
}

module.exports = SfxItem;
