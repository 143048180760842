var uuid = require("uuid");

function isIOS() {
    const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);

    const iOS13_iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const iOS1to12quirk = function () {
        var audio = new Audio(); // temporary Audio object
        audio.volume = 0.5; // has no effect on iOS <= 12
        return audio.volume === 1;
    };

    return !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());
}

//[a,b)
function rand(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
}
function sin(degree) {
    return Math.sin((degree * Math.PI) / 180);
}
function cos(degree) {
    return Math.cos((degree * Math.PI) / 180);
}

function shareKakao(title, description, imageUrl, url) {

    console.log("debug - shareKakao come");
    if (isMobileApp()) {
        sendMessageToApp(MESSAGE_KAKAO);
        return;
    }

    try {
        if (Kakao) {
            Kakao.init("514744510f62349e01afdd6cd40f43ed");
        };
    } catch (e) {
        console.log("kakao init error");
    };

    Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
            title: title,
            description: description,
            imageUrl: imageUrl,
            link: {
                mobileWebUrl: url,
                webUrl: url,
            },
        },
        buttons: [
            {
                title: "확인",
                link: {
                    mobileWebUrl: url,
                    webUrl: url,
                },
            },
        ],
    });
}

function copyLink(url) {

    var textarea = document.createElement("textarea");
    document.body.appendChild(textarea);

    if (url == null) {
        url = window.location.origin;
    }
    textarea.value = url;
    textarea.select();

    document.execCommand("copy");
    document.body.removeChild(textarea);
}

function makeUuid() {
    var id = uuid.v4();
}

export default { isIOS, rand, sin, cos, shareKakao, copyLink, makeUuid }