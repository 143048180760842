<template>
  <div style="text-align: center" v-show="isShowing">
    <img
      class="btn-kakao mr-1"
      @click="shareKakao"
      src="https://developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png"
    />
    <img
      class="btn-copy"
      @click="copyLink"
      src="https://yhmango.com/wp-content/uploads/2022/04/share-icon-resize.png"
    />
  </div>
</template>

<script>
import NetworkUtils from "./../common/NetworkUtils.js";
import CommonUtils from "./../common/CommonUtils.js";
import GlobalUtils from "./../common/GlobalUtils";

export default {
  props: {
    position: String,
  },
  mounted() {
    console.log(this.position);

    if (GlobalUtils.BROWSER_TYPE == GlobalUtils.BROWSER_TYPE_IOS_APP) {
      this.isShowing = false;
    } else {
      this.isShowing = true;
    }
    if (this.position == "idol") {
      this.title = "아이돌 닮은꼴 테스트";
      this.content = "100명의 아이돌 중 나와 닮은 아이돌은 누구 일까요?";
      this.imgUrl =
        "https://yhmango.com/wp-content/uploads/2022/03/photoframe_50.png";
    }
  },
  data: function () {
    return {
      imgUrl: "https://yhmango.com/wp-content/uploads/2022/03/share.png",
      title: "디망고 페이지",
      content: "소소한 게임 모음",
      isShowing: false,
    };
  },
  methods: {
    shareKakao() {
      NetworkUtils.countVisitors(this.position, "share");
      CommonUtils.shareKakao(
        this.title,
        this.content,
        this.imgUrl,
        window.location.origin
      );
    },
    copyLink() {
      NetworkUtils.countVisitors(this.position, "copy");
      CommonUtils.copyLink(window.location.origin);
      alert("공유 링크가 클립보드에 복사 되었습니다.");
    },
  },
};
</script>

<style scoped>
.btn-kakao {
  height: 42px;
  border-radius: 10px;
  border: 2px solid #636366;
}
.btn-copy {
  height: 42px;
  border-radius: 10px;
  background-color: #415964;
  border: 2px solid #636366;
}
</style>
