<template>
  <div style="text-align: center" v-show="showBanners">
    <!-- <span class="mr-1">
      <a
        href="https://play.google.com/store/apps/details?id=com.makegametogether.dimango_android&hl=ko&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
      >
        <img
          :src="require('../assets/google-play-badge.svg')"
          contain
          height="40"
      /></a>
    </span>
    <span class="ml-1">
      <a
        href="https://apps.apple.com/us/app/dimango/id1632801827?itsct=apps_box_promote_link&itscg=30200"
      >
        <img
          :src="require('../assets/apple-app-store-badge.svg')"
          contain
          height="40"
        />
      </a>
    </span> -->
  </div>
</template>

<script>
import GlobalUtils from "./../common/GlobalUtils";
export default {
  data: function () {
    return { showBanners: false };
  },
  mounted() {
    this.showBanners =
      GlobalUtils.BROWSER_TYPE == GlobalUtils.BROWSER_TYPE_NORMAL;
  },
  methods: {},
};
</script>

<style scoped>
.badges {
  display: inline-block;
}
</style>
